import {fromEvent, Observable, Subscription, timer} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, map, retryWhen, startWith, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EventEmitter, Inject, Injectable, InjectionToken, OnDestroy, Optional} from '@angular/core';
import {clone, defaults, isNil} from 'lodash';
import {RtPlatformService} from '../rt-platform/rt-platform.service';
import {WINDOW} from '../universal/window/window.service';


export interface ConnectionState {
  /**
   * "True" if browser has network connection. Determined by Window objects "online" / "offline" events.
   */
  hasNetworkConnection: boolean;
  /**
   * "True" if browser has Internet access. Determined by heartbeat system which periodically makes request to heartbeat Url.
   */
  hasInternetAccess: boolean;
}

/**
 * Instance of this interface could be used to configure "ConnectionService".
 */
export interface ConnectionServiceOptions {
  /**
   * Controls the Internet connectivity heartbeat system. Default value is 'true'.
   */
  enableHeartbeat?: boolean;
  /**
   * Url used for checking Internet connectivity, heartbeat system periodically makes "HEAD" requests to this URL to determine Internet
   * connection status. Default value is "//internethealthtest.org".
   */
  heartbeatUrl?: string;
  /**
   * Interval used to check Internet connectivity specified in milliseconds. Default value is "30000".
   */
  heartbeatInterval?: number;
  /**
   * Interval used to retry Internet connectivity checks when an error is detected (when no Internet connection). Default value is "1000".
   */
  heartbeatRetryInterval?: number;
  /**
   * HTTP method used for requesting heartbeat Url. Default is 'head'.
   */
  requestMethod?: 'get' | 'post' | 'head' | 'options';
}

/**
 * InjectionToken for specifing ConnectionService options.
 */
export const ConnectionServiceOptionsToken: InjectionToken<ConnectionServiceOptions> = new InjectionToken('ConnectionServiceOptionsToken');

@Injectable({
  providedIn: 'root'
})
export class RtConnectionService implements OnDestroy {

  private static DEFAULT_OPTIONS: ConnectionServiceOptions = {
    enableHeartbeat: false,
    heartbeatUrl: '/assets/ping.json',
    requestMethod: 'get',
    heartbeatInterval: 3000,
    heartbeatRetryInterval: 5000,
  };

  private currentState: ConnectionState = {
    hasInternetAccess: false,
    hasNetworkConnection: this.window.navigator.onLine
  };

  private stateChangeEventEmitter = new EventEmitter<ConnectionState>();


  private offlineSubscription: Subscription;
  private onlineSubscription: Subscription;
  private httpSubscription: Subscription;
  private serviceOptions: ConnectionServiceOptions;

  /**
   * Current ConnectionService options. Notice that changing values of the returned object has not effect on service execution.
   * You should use "updateOptions" function.
   */
  get options(): ConnectionServiceOptions {
    return clone(this.serviceOptions);
  }

  constructor(
    @Inject(WINDOW) private window,
    private http: HttpClient,
    @Inject(ConnectionServiceOptionsToken) @Optional() options: ConnectionServiceOptions,
    private platform: RtPlatformService,
  ) {
    this.serviceOptions = defaults({}, options, RtConnectionService.DEFAULT_OPTIONS);
    this.checkNetworkState();
    this.checkInternetState();
  }

  private emitEvent() {
    this.stateChangeEventEmitter.emit(this.currentState);
  }

  private checkInternetState() {
    if (this.platform.isServer) {
      return;
    }
    if (!isNil(this.httpSubscription)) {
      this.httpSubscription.unsubscribe();
    }
    if (this.serviceOptions.enableHeartbeat) {
      this.httpSubscription = timer(0, this.serviceOptions.heartbeatInterval)
        .pipe(
          switchMap(() => this.http[this.serviceOptions.requestMethod](this.serviceOptions.heartbeatUrl, {responseType: 'text'})),
          retryWhen(errors =>
            errors.pipe(
              // log error message
              tap(val => {
                console.error('Http error:', val);
                this.currentState.hasInternetAccess = false;
                this.emitEvent();
              }),
              // restart after 5 seconds
              delay(this.serviceOptions.heartbeatRetryInterval)
            )
          ),
        )
        .subscribe(result => {
          this.currentState.hasInternetAccess = true;
          this.emitEvent();
        });
    } else {
      this.httpSubscription = this.http[this.serviceOptions.requestMethod](this.serviceOptions.heartbeatUrl, {responseType: 'text'})
        .pipe(
          retryWhen(errors =>
            errors.pipe(
              // log error message
              tap(val => {
                console.error('Http error:', val);
                this.currentState.hasInternetAccess = false;
                this.emitEvent();
              }),
              // restart after 5 seconds
              delay(this.serviceOptions.heartbeatRetryInterval)
            )
          ),
        )
        .subscribe(result => {
          this.currentState.hasInternetAccess = true;
          this.emitEvent();
        });
      // this.currentState.hasInternetAccess = false;
      // this.emitEvent();
    }
  }

  private checkNetworkState() {
    this.onlineSubscription = fromEvent(this.window, 'online').subscribe(() => {
      this.currentState.hasNetworkConnection = true;
      this.checkInternetState();
      this.emitEvent();
    });

    this.offlineSubscription = fromEvent(this.window, 'offline').subscribe(() => {
      this.currentState.hasNetworkConnection = false;
      this.checkInternetState();
      this.emitEvent();
    });
  }

  monitor(): Observable<ConnectionState> {
    return this.stateChangeEventEmitter.pipe(
      debounceTime(300),
      startWith(this.currentState),
    );
  }

  monitoringConnection(): Observable<boolean> {
    return this.stateChangeEventEmitter.pipe(
      debounceTime(300),
      startWith(this.currentState),
      map(state => state.hasNetworkConnection && state.hasInternetAccess),
      distinctUntilChanged()
    );
  }

  get online$(): Observable<boolean> {
    return this.monitor().pipe(
      map(state => state.hasNetworkConnection && (
        state.hasInternetAccess && this.serviceOptions.enableHeartbeat || !this.serviceOptions.enableHeartbeat
      )),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.offlineSubscription.unsubscribe();
    this.onlineSubscription.unsubscribe();
  }

  getConnectionState() {
    return this.currentState.hasInternetAccess && this.currentState.hasNetworkConnection;
  }

  /**
   * Update options of the service. You could specify partial options object. Values that are not specified will use default / previous
   * option values.
   * @param options Partial option values.
   */
  updateOptions(options: Partial<ConnectionServiceOptions>) {
    this.serviceOptions = defaults({}, options, this.serviceOptions);
    this.checkInternetState();
  }
}
